<template>
  <Content>
    <template #contentTitle>{{ $t('menu.transit_label_change_detail') }}</template>
    <template #contentBody="wrap">
      <a-spin :spinning="loading">
        <a-row :gutter="[8, 8]" type="flex" justify="space-between">
          <a-col>
            <a-row :gutter="[8, 8]">
              <a-col :span="24">
                {{ $t('warehouse.belonging_to_warehouse') }}: 
                <span v-if="pageInfo.warehouse">{{ pageInfo.warehouse.warehouseNo }}</span>
                <span v-else>-</span>
              </a-col>
              <a-col :span="24">{{ $t('warehouse.label_task_name') }}: {{ pageInfo.taskName }}</a-col>
              <a-col :span="24">{{ $t('warehouse.tag_task_number') }}: {{ pageInfo.taskNo }}</a-col>
              <a-col :span="24">{{ $t('warehouse.shipment_name') }}: {{ pageInfo.oldShipmentName || '-' }}</a-col>
              <a-col :span="24">{{ $t('warehouse.se_shipment_number') }}: {{ pageInfo.shipment?.seShipmentNo || '-' }}</a-col>
            </a-row>
          </a-col>
          <a-col :lg="6" :span="24">
            <a-row :gutter="[8, 8]">
              <a-col :span="24" style="text-align: center;">{{ $t('warehouse.task_status') }}</a-col>
              <a-col :span="24" style="text-align: center;">
                <a-typography-text type="secondary" v-if="pageInfo.status === labelTaskStatusEnum.cancelled">{{ $t($enumLangkey('labelTaskStatus', pageInfo.status)) }}</a-typography-text>
                <a-typography-text type="success" v-else>{{ $t($enumLangkey('labelTaskStatus', pageInfo.status)) }}</a-typography-text>
              </a-col>
              <a-col :span="24" style="text-align: center;">
                <a-button v-if="pageInfo.status === labelTaskStatusEnum.ordered" @click="handleCancel">{{ $t('warehouse.cancel_task') }}</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-table
          style="overflow-x: auto;"
          class="mt-3"
          size="small"
          :columns="columns"
          :data-source="boxList"
          :pagination="false"
          :row-key="(record) => record.id"
        >
          <template #serialNumber="{ index, record }">
            {{ index + 1 }}
          </template>
          <template #boxNo="{ record }">
            {{ record.whBox?.boxNo || '-' }}
            <a-tag v-if="record.isCancelled" class="ml-2">{{ $t('logistics.canceled') }}</a-tag>
          </template>
          <template #selfBoxNo="{ record }">
            {{ record.whBox?.selfBoxNo || '-' }}
          </template>
          <template #boxLabelUrl="{ record }">
            <CFileDisplay v-if="record.boxLabelUrl" :fileUrl="record.boxLabelUrl" />
            <span v-else>-</span>
          </template>
          <template #labelCount="{ record }">
            {{ record.labelCount || '-' }}
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.boxProductRls"
              :pagination="false"
              :row-key="(record) => record.id"
            >
              <template #serialNumber="{ index }">
                {{ index + 1 }}
              </template>
              <template #productIdentifyImgs="{ record }">
                <a-row :gutter="8" v-if="record.productIdentifyImgs?.length">
                  <a-col v-for="(item, index) in record.productIdentifyImgs" :key="index">
                    <CFileDisplay :fileUrl="item" />
                  </a-col>
                </a-row>
                <span v-else>-</span>
              </template>
              <template #productLabelUrl="{ record }">
                <CFileDisplay :fileUrl="record.labelUrl" />
              </template>
              <template #checkProductCount="{ record }">
                {{ record.checkProductCount || '0' }}
              </template>
            </a-table>
          </template>
        </a-table>
        <a-row class="mt-3" :gutter="[16, 16]" type="flex" justify="space-between">
          <a-col :md="24" :xl="12">
            <a-row :gutter="[16, 8]">
              <a-col :span="24" v-if="pageInfo.isChangeShipmentName">{{ $t('warehouse.new_shipment_name') }}: <span class="text-error">{{ pageInfo.newShipmentName }}</span></a-col>
              <a-col :span="24">{{ $t('warehouse.label_service_type') }}: <span style="font-weight: bold;">{{ $t($enumLangkey('labelTaskType', pageInfo.labelType)) }}</span></a-col>
            </a-row>
          </a-col>
          <a-col>
            <CFee v-if="fee.totalFee" :fees="fee.fees" :totalFee="fee.totalFee" />
          </a-col>
        </a-row>

        <a-row type="flex"
               justify="end"
               class=" mb-5 mt-3">
              <a-col>
              <div style="width: 400px">
                <a-row type="flex" justify="center" :gutter="[24, 12]">
                <a-col class="">
                  <a-button @click="handleBack ">{{$t('common.backtrack')}}</a-button>
                </a-col>
              </a-row>
              </div>
            </a-col>
          </a-row>

      </a-spin>
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Button, Col, Popover, Row, Spin, Table, Modal, Typography, message, Tag } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CFileDisplay from '@/views/components/CFileDisplay.vue';
import CFee from '@/views/components/CFee.vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n/index";
import { useTab } from "@/hooks/tabs/index";
import {
  getTransitsLabelChangeTaskById,
  getTransitsLabelChangeTaskDetailList,
  cancelTransitsLabelChangeTask,
} from '@/api/modules/transit/labelChange.js';
import { setFormStateValue } from '@/utils/general.js';
import { labelTaskStatus as labelTaskStatusEnum } from '@/enum/enum.json';

export default defineComponent({
  name: 'transit_label_change_detail',
  components: {
    ASpin: Spin,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    APopover: Popover,
    ATypographyText: Typography.Text,
    ATag: Tag,
    Content,
    CFileDisplay,
    CFee,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    
    const state = reactive({
      loading: false,
      formState: {
        id: null,
      },
      pageInfo: {
        warehouse: null,
        taskName: null,
        taskNo: null,
        shipment: null,
        isChangeShipmentName: false,
        newShipmentName: null,
        oldShipmentName: null,
        labelType: null,
        status: null,
      },
      fee: {
        fees: [],
        totalFee: null,
      },
      boxList: [],
    });

    const columns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.box_id'),
        slots: {
          customRender: 'boxNo',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('common.user_defined_box_no'),
        slots: {
          customRender: 'selfBoxNo',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.new_box_label'),
        slots: {
          customRender: 'boxLabelUrl',
        }
      },
      {
        width: 200,
        title: () => vueI18n.t('warehouse.box_label_quantity'),
        slots: {
          customRender: 'labelCount',
        }
      },
    ];

    const innerColumns = [
      {
        width: 80,
        title: () => vueI18n.t('warehouse.serial_number'),
        slots: {
          customRender: 'serialNumber',
        }
      },
      {
        dataIndex: 'productIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.identifiable_product_information'),
      },
      {
        width: 150,
        title: () => vueI18n.t('warehouse.recognizable_product_images'),
        slots: {
          customRender: 'productIdentifyImgs',
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_labeling_document'),
        slots: {
          customRender: 'productLabelUrl',
        }
      },
      {
        dataIndex: 'newProductIdentify',
        width: 120,
        title: () => vueI18n.t('warehouse.new_product_label'),
      },
      {
        dataIndex: 'productCount',
        width: 100,
        title: () => vueI18n.t('warehouse.product_quantity'),
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.quantity_of_finished_products'),
        slots: {
          customRender: 'checkProductCount',
        }
      },
    ];

    const handleCancel = () => {
      Modal.confirm({
        title: vueI18n.t("common.operation"),
        content: vueI18n.t("common.are_you_sure_cancel"),
        okText: vueI18n.t("common.confirm"),
        cancelText: vueI18n.t("common.cancel"),
        onOk: () => {
          return cancelTransitsLabelChangeTask({ id: state.formState.id }).then(() => {
            message.success(vueI18n.t("common.successfully_canceled"));
            getDetail();
          });
        },
      });
    }

    const handleBack = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transit_label_change_list" });
    };

    const getDetail = async () => {
      try {
        state.loading = true;
        let { result: shipmentInfo } = await getTransitsLabelChangeTaskById({ id: state.formState.id });
        if (shipmentInfo) {
          setFormStateValue(state.pageInfo, shipmentInfo);
          if (shipmentInfo.fee) {
            state.fee = shipmentInfo.fee;
          }
        }

        let { result: list } = await getTransitsLabelChangeTaskDetailList({ taskIds: [state.formState.id], isNeedBox: true, isNeedProductRls: true })
        if (Array.isArray(list)) {
          state.boxList = list;
        } else {
          state.boxList = [];
        }
      } catch (error) {
      } finally {
        state.loading = false;
      }
    }

    onMounted(() => {
      let params = route.params;
      if ('id' in params) {
        state.formState.id = params.id;
        getDetail();
      }
    });

    return {
      ...toRefs(state),
      labelTaskStatusEnum,
      columns,
      innerColumns,
      handleCancel,
      handleBack,
    }
  }
})
</script>

<style scoped>

</style>